import React from "react";
import PrimaryLayout from "../components/layout/primary-layout";
import SEO from "../components/seo";

const NotFoundPage = ({ location }) => (
  <PrimaryLayout location={location}>
    <SEO title="404: Not found" />
    <div className="mx-auto my-5">
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </PrimaryLayout>
);

export default NotFoundPage;
