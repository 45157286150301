import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import PrimaryHeader from "../primary-header";
import Footer from "../footer";

import "bootstrap/scss/bootstrap.scss";
//import "./_layout.scss";
//import "./_animations.scss";
import LoadingScreen from "react-loading-screen";
import Logo from "../../../static/logo/banban-logo.png";
if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]');
}

const PrimaryLayout = ({ children, location }) => {
  const [loading, isloading] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      isloading(false);
    }, 1000);
  }, []);
  return (
    <>
      {loading ? (
        <LoadingScreen
          loading={true}
          bgColor="#f1f1f1"
          spinnerColor="#c9c8f7"
          textColor="#676767"
          logoSrc={Logo}
        />
      ) : (
        <>
          <PrimaryHeader location={location} />
          <main>{children}</main>
          <Footer location={location} />
        </>
      )}
    </>
  );
};

PrimaryLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PrimaryLayout;
