import React, { useState } from "react";
import { Link } from "gatsby";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
} from "reactstrap";
import Logo from "../../../static/logo/banban-logo.png";
import "./_header.scss";

const PrimaryHeader = ({ location = false }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const togglelan = () => setDropdownOpen(!dropdownOpen);

  const isActive = ({ isCurrent }) =>
    isCurrent
      ? { className: "nav-link primary-nav-link ml-lg-4 activeClass" }
      : null;

  return (
    <header>
      <div className="container">
        <div className="primary-header-desktop">
          <Navbar className="primary-nav" color="white" light expand="md">
            <NavbarBrand href={!location ? "/" : "/fi"}>
              <img
                className="img-fluid header-logo"
                src={Logo}
                alt="Header Logo"
              />
            </NavbarBrand>
            <NavbarToggler onClick={toggle} />
            <Collapse isOpen={isOpen} navbar>
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <Link
                    getProps={isActive}
                    className="nav-link primary-nav-link ml-md-4"
                    to={!location ? "/#what-we-do" : "/fi/#what-we-do"}
                  >
                    What we do
                    <hr />
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    getProps={isActive}
                    className="nav-link primary-nav-link ml-md-4"
                    to={!location ? "/#technologies" : "/fi/#technologies"}
                  >
                    Technologies
                    <hr />
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    getProps={isActive}
                    className="nav-link primary-nav-link ml-md-4"
                    to={!location ? "/#portfolio" : "/fi/#portfolio"}
                  >
                    Portfolio
                    <hr />
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    getProps={isActive}
                    className="nav-link primary-nav-link ml-md-4"
                    to={!location ? "/blogs" : "/fi/blogs"}
                  >
                    Blog
                    <hr />
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    getProps={isActive}
                    className="nav-link primary-nav-link ml-md-4"
                    to={!location ? "/#contact" : "/fi/#contact"}
                  >
                    Contact
                    <hr />
                  </Link>
                </NavItem>
              </Nav>
            </Collapse>
          </Navbar>
        </div>

        <div className="primary-header-mob">
          <div className="row header-mob pt-3 pb-3">
            <div className="col-6">
              <NavbarBrand href="/">
                <img
                  className="img-fluid header-logo"
                  src={Logo}
                  alt="Header Logo"
                />
              </NavbarBrand>
            </div>
            <div className="col-6">
              <Link
                className="btn blog-btn-mob"
                to={!location ? "/blogs" : "/fi/blogs"}
              >
                Blog
              </Link>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default PrimaryHeader;
